<template>
  <div

    :class="{ flip: isFooter }"
    style="display: grid"
  >
    <div
      class="d-none d-md-inline heading"
      :style="{ color: headingColor || '#333'}"
    >
      {{ heading }}
    </div>

    <svg
      viewBox="0 0 500 50"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <defs>
        <filter id="drop-shadow">
          <feDropShadow
            dx="-0.4"
            dy="-0.4"
            stdDeviation="1"
            flood-color="#333333"
          />
        </filter>
      </defs>

      <path
        d="M 0 10 L 0 49 L 500 48 L 0 10 Z"
        :style="{ fill: leftColor }"
        style="filter: url(#drop-shadow);"
        stroke="0"
      />

      <path
        d="M 500 10 L 500 51 L -20 51 L 500 10 Z"
        :style="{ fill: rightColor }"
        style="filter: url(#drop-shadow);"
        stroke="0"
      />

    </svg>
  </div>
</template>

<script>
//    :style="{ backgroundColor: backColor }"
export default {
    name: 'TccEdge',
    components: {},
    props: {
        leftColor: {
            type: String,
            default: '#3898ec'
        },
        backColor: {
            type: String,
            default: '#ffffff'
        },
        rightColor: String,
        heading: String,
        headingColor: String,
        isFooter: Boolean
    },
    data() {
        return {
        };
    },
    watch: {
    },
    mounted() {
    },
    methods: {
    }
};
</script>

<style scoped>
.heading {
    position: absolute;
    top: 50%;
    left: 1vw;
    font-size: larger;
}
.flip {
    transform: rotate(180deg);
    /* transform-origin: 50% 50%; */
}
</style>