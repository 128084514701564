<template>
  <!-- <div class="d-flex align-center justify-center pt-12"> -->
  <div
    class="text-center"
    style="background-color: #ffffff"
  >
    <div 
      v-if="false"
      style="height: 50px"
      :style="{ backgroundColor: sitePub.background, color: sitePub.fontColor }"
    />

    <div
      class="hero-bg"
      style="height: 300px; "
    />

    <v-container
      fluid
      style="background-color: #f4f4f4;"
    > 
      <v-row>
        <v-img
          alt="Logo"
          class="shrink mx-auto my-4"
          contain
          :src=" sitePub.logo || '@/assets/tcc-logo.png'"
          transition="scale-transition"
          width="70%"
          min-height="100px"
          max-width="500"
          max-height="200"
        />
      </v-row>
      <v-row>
        <v-col>
          <a
            :href="gmaps()"
            target="_blank"
            style="text-decoration: none; color: #333"
          >
            <h1 style="display: inline-block;">{{ sitePub.name }}<v-icon>mdi-map-marker</v-icon></h1>
          </a>
          <follow
            v-if="customer.email"
            :orgsite="orgSite"
            :init-state="inFollowList(orgSite)"
          />
        </v-col>
      </v-row>
      <v-row
        justify="center"
        class="py-4"
        style="min-height: 100vh;"
      >
        <v-col
          v-for="(post,i) in posts"
          :key="i"
          cols="auto"
        >
          <postcard 
            :post="post"
            store-mode
            @click="postClick(i)"
          />
        </v-col>
        <v-col v-if="!posts.length">
          No clearances currently available from this store.<br>
          [See these other clearances ...]
        </v-col>
      </v-row>
    </v-container>

    <tcc-edge
      :left-color="sitePub.background"
      right-color="#f4f4f4"
      is-footer
    />
      
    <div
      class="hero-bg"
      style="margin-top: -6vw; margin-bottom: 0px"
    />
    
    <tcc-foot tyle="margin-top: 100px" />
  </div>
</template>

<script>

import postcard from "../components/PostCard.vue";
import follow from "../components/Follow.vue";
import tccEdge from "@/components/tccEdge.vue";
import tccFoot from "@/components/tccFooter.vue";

export default {
    name: 'Store',
    components: {
        postcard,
        follow,
        tccEdge,
        tccFoot
    },
    data() {
        return {
            orgSite: "",
            sitePub: {},
            posts: [],
            customer: {}
        };
    },
    computed: {
        storeTheme: function(){
            return {

            };
        }
    },
    async mounted(){
        console.log( "STORE PAGE MOUNTED" );

        let orgSite = this.$route.params.orgsite;
        // debugger; // eslint-disable-line no-debugger
        if( !orgSite && window.location.pathname.length == 1 )this.$router.replace( { path: '/' } );
        if( !orgSite )orgSite = window.location.pathname.substr( 1 ); //org.site
        let os = orgSite.split( '.' );
        console.log( os );

        if( os.length != 2 || !os[0] || !os[1] )return this.$router.replace( { path: '/' } );//error

        let res = await this.$http.getJSON( 'sites?storePosts=' + orgSite );
        if( !res.ok )return this.$router.replace( { path: '/' } ); //error
        console.log( res );
        this.sitePub = res.data.site;
        this.posts = res.data.posts;
        this.orgSite = orgSite;
        document.title = "The Clearance Club - " + this.sitePub.name;
        if( this.$store.siteCache )this.$store.siteCache[orgSite] = this.sitePub;//keep for post pages

        res = await this.$http.getCustomer();
        console.log( res );
        if( res.ok ){
            this.customer = res.data;
            console.log( this.customer );
            this.$emit( 'user', res.data, res.token );
        }
        

    },
    methods: {
        inFollowList( orgSite ){

            if( !this.customer || !this.customer.follows )return false;
            if( this.customer.follows.indexOf( orgSite ) == -1 )return false;
            return true;

        },
        gmaps(){
            return "https://www.google.com/maps/search/?api=1&query=" + encodeURIComponent( this.sitePub.name );
        }
    }
};
</script>

